// client/src/components/Pathologies.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Generalcss.css'; // Assurez-vous d'avoir un fichier CSS pour le style

const Pathologies = () => {
  const [pathologies, setPathologies] = useState([]);
  const [newPathology, setNewPathology] = useState({ name: '', description: '' });

  useEffect(() => {
    const fetchPathologies = async () => {
      try {
        const response = await axios.get('/api/pathologies');
        setPathologies(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des pathologies', error);
      }
    };

    fetchPathologies();
  }, []);

  const handleAddPathology = async () => {
    try {
      const response = await axios.post('/api/pathologies', newPathology);
      setPathologies([...pathologies, response.data]);
      setNewPathology({ name: '', description: '' });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la pathologie', error);
    }
  };

  return (
    <div>
      <h2>Exercices par Pathologie</h2>
      <input
        type="text"
        value={newPathology.name}
        onChange={(e) => setNewPathology({ ...newPathology, name: e.target.value })}
        placeholder="Nom de la pathologie"
      />
      <input
        type="text"
        value={newPathology.description}
        onChange={(e) => setNewPathology({ ...newPathology, description: e.target.value })}
        placeholder="Description de la pathologie"
      />
      <button onClick={handleAddPathology}>Ajouter</button>
      <ul>
        {pathologies.map((pathology) => (
          <li key={pathology._id}>
            <strong>{pathology.name}</strong>: {pathology.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pathologies;
