// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* client/src/components/Navbar.css */
.navbar {
    background-color: #333;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }
  
  .navbar h1 {
    margin: 0;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 15px;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,sBAAsB;IACtB,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":["/* client/src/components/Navbar.css */\n.navbar {\n    background-color: #333;\n    padding: 10px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    color: white;\n  }\n  \n  .navbar h1 {\n    margin: 0;\n  }\n  \n  .nav-links {\n    list-style: none;\n    display: flex;\n    gap: 15px;\n  }\n  \n  .nav-links a {\n    color: white;\n    text-decoration: none;\n  }\n  \n  .nav-links a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
